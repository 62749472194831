<template>
  <div class="wrapper" v-if="show">
    <b-skeleton v-if="loading" width="200px" height="20px" />
    <a v-if="canCreateOtherExpenses" class="inline-action" @click="$emit('startOtherExpensesGuide')">
      <Plus class="icon mx-1" />
      <div class="button-text">Criar outras despesas</div>
    </a>
    <a v-if="canShowOtherExpenses" class="inline-action" @click="$emit('openOtherExpensesGuide', expensableId)">
      <Eye class="icon fill mx-1" />
      <span class="button-text">Guia Outras Despesas</span>
    </a>
    <a v-if="canShowMainGuide" class="inline-action" @click="$emit('openSpsadtGuide', getTissGuideId)">
      <Eye class="icon fill mx-1" />
      <span class="button-text">Guia Principal</span>
    </a>
  </div>

    <!-- <b-row
      v-if="tissGuide?.guide_type === GUIDE_TYPE_OTHER_EXPENSE || (form.id && tissGuide?.guide_type === GUIDE_TYPE_SPSADT)"
      class="bar-padding"
    >
        <b-col cols="12" class="fixed-bar">
            <span
                v-if="tissGuide?.guide_type === 'App\\Models\\TissGuide\\TissGuideOtherExpense'"
                class="bg-white"
            >
                <span v-b-tooltip.hover :title="!Boolean(form.expensable_id) && !form?.expensable_id?.label?.includes('SP/SADT') ? 'Selecione uma guia SP/SADT' : null">
                <b-button
                    :disabled="!Boolean(form.expensable_id) || !form?.expensable_id?.label?.includes('SP/SADT')"
                    variant="link"
                    class="d-flex"
                    @click="openSpsadtGuide"
                >
                    <Eye
                        v-if="Boolean(form.expensable_id) && form?.expensable_id?.label?.includes('SP/SADT')"
                        class="eye-icon stroke mt-1"
                    />
                    <Plus
                        v-else
                        class="icon stroke mt-1"
                    />

                    <span class="button-text">
                        Guia Principal
                    </span>
                </b-button>
                </span>
            </span>

            <span
                v-else-if="form.id && tissGuide?.guide_type === 'App\\Models\\TissGuide\\TissGuideSpSadt'"
                class="bg-white"
            >
                <b-button
                v-if="!expensable"
                variant="link"
                class="d-flex"
                @click="createOtherExpensesGuide"
                >
                <Plus class="icon stroke mt-1"/>

                <span class="button-text">
                    Criar outras despesas
                </span>
                </b-button>
                <b-button
                v-else
                @click="openOtherExpensesGuide(expensable)"
                variant="link"
                class="d-flex"
                >
                <Eye class="eye-icon stroke mt-1"/>

                <span class="button-text">
                    Guia Outras Despesas
                </span>
                </b-button>
            </span>
        </b-col>
    </b-row> -->
</template>

<script>
import api from '@/modules/tiss/manageGuides/api'
import {
  GUIDE_TYPE_SPSADT,
  GUIDE_TYPE_HOSPITALIZATION_SUMMARY
} from '@/modules/tiss/manageGuides/utils/guideHelper'
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'TopBarButtonsModule',
  components: {
    Eye: () => import('@/assets/icons/eye.svg'),
    Plus: () => import('@/assets/icons/plus-blue.svg')
  },
  props: {
    form: Object,
    tissGuide: Object,
  },
  data() {
    return {
      loading: false,
      expensableId: null,
    }
  },
  mounted() {
    this?.tissGuide && [GUIDE_TYPE_SPSADT, GUIDE_TYPE_HOSPITALIZATION_SUMMARY].includes(this.tissGuide.guide_type) && this.getExpensableId()
  },
  computed: {
    show() {
      return this.tissGuide.expensable_type === GUIDE_TYPE_SPSADT || (this.tissGuide.id && this.tissGuide.guide_type === GUIDE_TYPE_SPSADT)
    },
    canCreateOtherExpenses() {
      return !this.loading && this.canEdit() && this.checkGuideType() && this.tissGuide.id && !this.expensableId
    },
    canShowOtherExpenses() {
      return !this.loading && this.checkGuideType() && this.tissGuide.id && this.expensableId
    },
    canShowMainGuide() {
      return !this.loading && this.tissGuide?.expensable?.tiss_guide?.id
    },
    getTissGuideId() {
      return this.tissGuide?.items?.find(item => item?.tiss_guide_id)?.tiss_guide_id;
    }
  },
  methods: {
    async getExpensableId() {
      this.loading = true
      try {
        const { data } = await api.getExpensableId(this.tissGuide.id)
        this.expensableId = data.id
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },
    checkGuideType() {
      return this.tissGuide.guide_type === GUIDE_TYPE_SPSADT
    },
    canEdit() {
      return this.tissGuide.situation === TissGuide.GUIDE_SITUATION_IN_PREPARATION
    }
  },
  watch: {
    tissGuide: {
      handler() {
        if (!this?.tissGuide) return
        [GUIDE_TYPE_SPSADT, GUIDE_TYPE_HOSPITALIZATION_SUMMARY].includes(this.tissGuide.guide_type) && this.getExpensableId()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 4px 6px;
    font-size: .9rem;
  }
  .inline-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--blue-500);
    font-weight: 500;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
  .icon {
    width: 20px;
    &.stroke {
      stroke: var(--blue-500) !important;
    }
    &.fill {
      fill: var(--blue-500) !important;
    }
  }
</style>